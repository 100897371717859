<section class="why-choose-us-area-two ptb-100 bg-dark">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="shape">
          <img src="assets/img/circle.png" alt="image" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="why-choose-us-text">
          <h2>Our Core Values</h2>
          <p>
            To grant our clients outstanding service. At DreamIT, we won't be
            rectified for being ordinary or even good at what we make. We strive
            to consistently provide and deliver top of the notch and outstanding
            services.
          </p>
          <ul class="features-list">
            <li>
              <span
                ><i class="flaticon-self-growth"></i> <b>Hunger</b> Be
                self-motivated and diligent, always thinking about the next step
                and the presenting opportunity</span
              >
            </li>
            <li>
              <span
                ><i class="flaticon-clock"></i> <b>Smart</b> Use the very best
                judgment and intuition, being self-aware and conscious of how a
                certain matter impacts others</span
              >
            </li>
            <li>
              <span
                ><i class="flaticon-ebook"></i> <b>Credit</b> is always put
                where its due, emphasizing the team over yourself, defining
                success as one rather than individually</span
              >
            </li>
            <li>
              <span
                ><i class="flaticon-factory"></i> <b>Accountability</b> Take
                ownership of your doings, communicate in good faith with
                partners and clients</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
