<section class="funfacts-style-three bg-dark bg-100f1f">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-3">
        <div class="single-funfacts-item">
          <h3><span [countUp]="20000"></span>+</h3>
          <p>End Users</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-3">
        <div class="single-funfacts-item">
          <h3><span [countUp]="50"></span>+</h3>
          <p>Deployed Systems</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-3">
        <div class="single-funfacts-item">
          <h3><span [countUp]="50"></span>+</h3>
          <p>Developers Worldwide</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-3">
        <div class="single-funfacts-item">
          <h3><span [countUp]="98"></span>%</h3>
          <p>Satisfaction Rate</p>
        </div>
      </div>
    </div>
  </div>
</section>
