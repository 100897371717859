import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { HeaderStyleTwoComponent } from '../../common/header-style-two/header-style-two.component';
import { RouterLink } from '@angular/router';
import { HeaderStyleSixComponent } from '../../common/header-style-six/header-style-six.component';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [HeaderStyleSixComponent, RouterLink],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  isToggled = false;

  constructor(public themeService: ThemeCustomizerService) {
    this.themeService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {}
}
