import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorPageComponent } from './components/pages/error-page/error-page.component';

import { HomePageElevenComponent } from './components/pages/home-page-eleven/home-page-eleven.component';

export const routes: Routes = [
  //   { path: 'x', component: HomePageOneComponent },
  //   { path: 'xx', component: HomePageTwoComponent },
  //   { path: 'index-3', component: HomePageThreeComponent },
  //   { path: 'index-4', component: HomePageFourComponent },
  //   { path: 'index-5', component: HomePageFiveComponent },
  //   { path: 'index-6', component: HomePageSixComponent },
  //   { path: 'index-7', component: HomePageSevenComponent },
  //   { path: 'index-8', component: HomePageEightComponent },
  //   { path: 'index-9', component: HomePageNineComponent },
  //   { path: 'index-10', component: HomePageTenComponent },
  { path: '', component: HomePageElevenComponent },
  //   { path: 'index-12', component: HomePageTwelveComponent },
  //   { path: 'index-13', component: HomePageThirteenComponent },
  //   { path: 'index-14', component: HomePageFourteenComponent },
  //   { path: 'about-style-1', component: AboutPageOneComponent },
  //   { path: 'about-style-2', component: AboutPageTwoComponent },
  //   { path: 'instructor-1', component: InstructorsPageOneComponent },
  //   { path: 'instructor-2', component: InstructorsPageTwoComponent },
  //   { path: 'instructor-3', component: InstructorsPageThreeComponent },
  //   { path: 'single-instructor', component: InstructorsDetailsPageComponent },
  //   { path: 'gallery', component: GalleryPageComponent },
  //   { path: 'events', component: EventsPageComponent },
  //   { path: 'single-events', component: EventsDetailsPageComponent },
  //   { path: 'pricing', component: PricingPageComponent },
  //   { path: 'feedback', component: FeedbackPageComponent },
  //   { path: 'partner', component: PartnerPageComponent },
  //   { path: 'login', component: LoginPageComponent },
  //   { path: 'register', component: RegisterPageComponent },
  //   { path: 'faqs', component: FaqPageComponent },
  //   { path: 'error-404', component: ErrorPageComponent },
  //   { path: 'coming-soon', component: ComingSoonPageComponent },
  //   { path: 'courses-category-style-1', component: CategoryPageOneComponent },
  //   { path: 'courses-category-style-2', component: CategoryPageTwoComponent },
  //   { path: 'courses-category-style-3', component: CategoryPageThreeComponent },
  //   { path: 'courses-list', component: CoursesListPageComponent },
  //   { path: 'courses-2-columns-style-1', component: CoursesGsocTwoPageComponent },
  //   {
  //     path: 'courses-3-columns-style-1',
  //     component: CoursesGsocThreePageComponent,
  //   },
  //   {
  //     path: 'courses-4-columns-style-1',
  //     component: CoursesGsocFourPageComponent,
  //   },
  //   {
  //     path: 'courses-2-columns-style-2',
  //     component: CoursesGstwcTwoPageComponent,
  //   },
  //   {
  //     path: 'courses-3-columns-style-2',
  //     component: CoursesGstwcThreePageComponent,
  //   },
  //   {
  //     path: 'courses-4-columns-style-2',
  //     component: CoursesGstwcFourPageComponent,
  //   },
  //   { path: 'courses-2-columns-style-3', component: CoursesGstcTwoPageComponent },
  //   {
  //     path: 'courses-3-columns-style-3',
  //     component: CoursesGstcThreePageComponent,
  //   },
  //   {
  //     path: 'courses-4-columns-style-3',
  //     component: CoursesGstcFourPageComponent,
  //   },
  //   { path: 'single-courses', component: CoursesDetailsPageComponent },
  //   { path: 'my-dashboard', component: MyDashboardPageComponent },
  //   { path: 'orders', component: OrdersPageComponent },
  //   { path: 'downloads', component: DownloadsPageComponent },
  //   { path: 'edit-address', component: EditAddressPageComponent },
  //   { path: 'edit-account', component: EditAccountPageComponent },
  //   { path: 'edit-billing-address', component: EditBillingAddressPageComponent },
  //   {
  //     path: 'edit-shipping-address',
  //     component: EditShippingAddressPageComponent,
  //   },
  //   { path: 'blog-style-1', component: BlogGridPageComponent },
  //   { path: 'blog-style-2', component: BlogRightSidebarPageComponent },
  //   { path: 'blog-style-3', component: BlogFullWidthPageComponent },
  //   { path: 'single-blog', component: BlogDetailsPageComponent },
  //   { path: 'shop-grid', component: ShopGridPageComponent },
  //   { path: 'shop-grid-fullwidth', component: ShopFullWidthPageComponent },
  //   { path: 'single-products', component: ProductsDetailsPageComponent },
  //   { path: 'cart', component: CartPageComponent },
  //   { path: 'checkout', component: CheckoutPageComponent },
  //   { path: 'contact', component: ContactPageComponent },
  // Here add new pages component

  { path: '**', component: ErrorPageComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
