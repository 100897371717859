<section class="learning-area pt-100 pb-70 bg-dark bg-0b0517">
  <div class="container">
    <div class="section-title">
      <h2>Our Services</h2>
      <p>
        We specialize in pioneering technology solutions, driving innovation
        through software development, artificial intelligence, and cutting-edge
        digital services.
      </p>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-learning-box">
          <div class="icon">
            <i class="flaticon-lcd"></i>
          </div>
          <h3>System Development</h3>
          <p>
            You envision it, we bring it to life through bespoke system
            development solutions.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-learning-box">
          <div class="icon">
            <i class="flaticon-shield"></i>
          </div>
          <h3>Security</h3>
          <p>
            We prioritize the safeguarding of your digital assets with robust
            security measures.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-learning-box">
          <div class="icon">
            <i class="flaticon-worldwide"></i>
          </div>
          <h3>Cloud Computing</h3>
          <p>
            Embrace the power of the cloud for enhanced scalability and
            efficiency in your operations.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
