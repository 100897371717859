<footer class="footer-area">
  <div class="container">
    <div class="logo">
      <a routerLink="/" class="d-inline-block"
        ><img src="assets/img/dreamit.png" width="200px" alt="image"
      /></a>
    </div>
    <p>© Dreamcloud Information Technology Services 2024</p>
  </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
