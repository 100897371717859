<div class="gym-home-slides">
  <div class="gym-main-banner bg-dark bg-1">
    <div class="container">
      <div class="gym-banner-content">
        <h1>We Humanize Technology</h1>
        <p>
          DreamIT was established to make lives better through technology. In
          the race towards advancement, we believe that technology is not meant
          to replace human intellect and creativity, at least not yet. Rather it
          was meant to make life a fuller, more human experience. We seek to
          create and add value through innova- tions that define the Eureka
          moments of human aspirations. At DreamIT, we bring dreams into
          reality.
        </p>
      </div>
    </div>
  </div>
</div>
