<app-header-style-six></app-header-style-six>

<div class="error-404-area" [class.dark-error-area]="themeService.isDark()">
  <div class="container">
    <div class="notfound">
      <div class="notfound-bg">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h1>Oops!</h1>
      <h3>Error 404 : Page Not Found</h3>
      <p>
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable.
      </p>
      <a routerLink="/" class="default-btn"
        ><i class="bx bx-home-circle icon-arrow before"></i
        ><span class="label">Back Home</span
        ><i class="bx bx-home-circle icon-arrow after"></i
      ></a>
    </div>
  </div>
</div>
