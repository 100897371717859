import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-why-choose-us-two',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './why-choose-us-two.component.html',
  styleUrls: ['./why-choose-us-two.component.scss']
})
export class WhyChooseUsTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}