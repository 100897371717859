<!-- Header -->
<header
  class="header-area header-color-white"
  [class.dark-header-area]="themeService.isDark()"
>
  <!-- Top Header -->
  <div class="top-header top-header-style-four">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-8">
          <ul class="top-header-contact-info">
            <li>
              Call:
              <a href="tel:+639 17 812 4331">+639 17 812 4331</a>
            </li>
          </ul>
          &nbsp;&nbsp;
          <ul class="top-header-contact-info">
            <li>
              Email:
              <a href="mailto:info&#64;dreamit.ph">info&#64;dreamit.ph</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Navbar -->
  <div class="navbar-area navbar-style-three" [ngClass]="{ sticky: isSticky }">
    <div class="container">
      <nav
        class="navbar navbar-expand-lg navbar-light bg-light"
        [class.active]="classApplied"
      >
        <a class="navbar-brand" routerLink="/">
          <img src="assets/img/dreamit.png" width="200px" alt="logo" />
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
          <span class="burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a href="mailto:info&#64;dreamit.ph">Let's Work Together</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
